import Api from "@/lib/Api"
import { Holding } from "@/models/companiesAndTerritories/partners"
import events from "@/constants/amplitude/companiesAndTerritories"
import amplitudeWrapper from "@/lib/amplitudeWrapper"

interface State {
    holdings: Holding[]
}

export default {
    state: {
        keepTerritoryHolding: false,
        holdings: []
    } as State,
    mutations: {
        updateHoldings(state: State, holdings: any) {
            state.holdings = holdings
        },
        resetHoldings(state: State) {
            state.holdings = []
        },
        createHolding(state: State, holding: Holding) {
            state.holdings.push(holding)
        },
        deleteHolding(state: State, holdingId: number) {
            state.holdings = state.holdings.filter((h: Holding) => h.id !== holdingId)
        },
        updateHolding(state: State, holding: Holding) {
            let holdingToEdit = state.holdings.find((h: Holding) => h.id === holding.id)
            holdingToEdit = holding
        },
        createHoldingBenefit(state: State, benefit: any) {
            let holdingIndex = state.holdings.findIndex(h => h.id === benefit.company)
            if (holdingIndex < 0) return
            state.holdings[holdingIndex].partner_benefit.push(benefit)
        },
        updateHoldingBenefit(state: State, benefit: any) {
            let holding = state.holdings.find((h: any) => h.id === benefit.company)
            if (!holding) return
            let partnerBenefit = holding.partner_benefit.find((b: any) => b.id === benefit.id)
            partnerBenefit = benefit
        },
        deleteHoldingBenefit(state: State, benefit: any) {
            let holding = state.holdings.find((h: any) => h.id === benefit.company)
            if (!holding) return
            holding.partner_benefit = holding.partner_benefit.filter((b: any) => b.id !== benefit.id)
        }
    },
    actions: {
        syncHoldings({ commit, state }: { commit: Function; state: State }, args: any) {
            let endpoint = "/admn/holdings_v2/"
            let keepTerritoryHolding = false
            if (args) {
                if (Object.keys(args).includes("keepTerritoryHolding")) {
                    keepTerritoryHolding = args.keepTerritoryHolding
                    delete args["keepTerritoryHolding"]
                }
                const urlParams = new URLSearchParams(Object.entries(args)).toString()
                endpoint += `?${urlParams}`
            }
            return new Promise((resolve, reject) => {
                Api.get(endpoint)
                    .then(holdings => {
                        const territoryHoldingIndex = holdings.findIndex(
                            (holding: Holding) => holding.partner_type === "territory_holding"
                        )
                        if (territoryHoldingIndex > -1) {
                            const territoryHolding = keepTerritoryHolding
                                ? holdings[territoryHoldingIndex]
                                : holdings.splice(territoryHoldingIndex, 1)[0]
                            if (territoryHolding) commit("updateTerritoryHoldingId", territoryHolding.id)
                        }
                        commit("updateHoldings", holdings)
                        resolve(holdings)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        syncHoldingDetails({ commit }: { commit: Function }, id: string | number) {
            let endpoint = `/admn/holdings_v2/${id}/?is_partner_page=true`
            return new Promise((resolve, reject) => {
                Api.get(endpoint)
                    .then(holding => {
                        commit("updateHolding", holding)
                        resolve(holding)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        createHolding({ commit, dispatch }: { commit: Function; dispatch: Function }, holding: any) {
            return new Promise((resolve, reject) => {
                Api.post("/admn/holdings_v2/", holding)
                    .then(createdHolding => {
                        amplitudeWrapper.logEvent(events.HOLDING_CREATED)
                        dispatch("syncHoldingDetails", createdHolding.id).then((holdingDetails: any) => {
                            commit("createHolding", holdingDetails)
                            resolve(holdingDetails)
                        })
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        updateHolding({ commit, dispatch }: { commit: Function; dispatch: Function }, holding: any) {
            return new Promise((resolve, reject) => {
                Api.patch(`/admn/holdings_v2/${holding.id}/`, holding)
                    .then(bufferHolding => {
                        commit("updateHolding", bufferHolding)
                        dispatch("syncHoldingDetails", holding.id).then((updatedHolding: Holding) => {
                            resolve(updatedHolding)
                        })
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        deleteHolding({ commit }: { commit: Function }, holdingId: any): Promise<void> {
            return new Promise((resolve, reject) => {
                Api.delete(`/admn/holdings_v2/${holdingId}/`)
                    .then(() => {
                        commit("deleteHolding", holdingId)
                        resolve()
                    })
                    .catch((err: any) => {
                        reject(err)
                    })
            })
        }
    },
    getters: {
        getHoldings: (state: State) => state.holdings
    }
}
