import Api from "@/lib/Api"

const MOBILITY_PACKAGE_PLAN_ENDPOINT = "/admn/mobility_package_plan/"
const MOBILITY_PACKAGE_REAL_ENDPOINT = "/partner_mobility_package/"

export default {
    actions: {
        getMobilityPackagesPlan() {
            return new Promise((resolve, reject) => {
                Api.get(MOBILITY_PACKAGE_PLAN_ENDPOINT)
                    .then((mobilityPackagesPlan: any) => {
                        mobilityPackagesPlan = mobilityPackagesPlan.sort((package1: any, package2: any) =>
                            package1.holding_name.localeCompare(package2.holding_name)
                        )
                        resolve(mobilityPackagesPlan)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        createMobilityPackagePlan({}, mobilityPackageToCreate: any) {
            return new Promise((resolve, reject) => {
                Api.post(MOBILITY_PACKAGE_PLAN_ENDPOINT, mobilityPackageToCreate)
                    .then(createdMobilityPackage => {
                        resolve(createdMobilityPackage)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        updateMobilityPackagePlan({}, mobilityPackageToUpdate: any) {
            return new Promise((resolve, reject) => {
                Api.patch(`${MOBILITY_PACKAGE_PLAN_ENDPOINT}${mobilityPackageToUpdate.id}/`, mobilityPackageToUpdate)
                    .then(updatedMobilityPackage => {
                        resolve(updatedMobilityPackage)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },

        getMobilityPackagesReal() {
            return new Promise((resolve, reject) => {
                Api.get(MOBILITY_PACKAGE_REAL_ENDPOINT)
                    .then((mobilityPackagesReal: any) => {
                        mobilityPackagesReal = mobilityPackagesReal.sort((package1: any, package2: any) =>
                            package1.holding_name.localeCompare(package2.holding_name)
                        )
                        resolve(mobilityPackagesReal)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        createMobilityPackageReal({}, mobilityPackageToCreate: any) {
            return new Promise((resolve, reject) => {
                Api.post(MOBILITY_PACKAGE_REAL_ENDPOINT, mobilityPackageToCreate)
                    .then(createdMobilityPackage => {
                        resolve(createdMobilityPackage)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        updateMobilityPackageReal({}, mobilityPackageToUpdate: any) {
            return new Promise((resolve, reject) => {
                Api.patch(`${MOBILITY_PACKAGE_REAL_ENDPOINT}${mobilityPackageToUpdate.id}/`, mobilityPackageToUpdate)
                    .then(updatedMobilityPackage => {
                        resolve(updatedMobilityPackage)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        }
    }
}
