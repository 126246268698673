import { PartnerAnchor } from "@/models/companiesAndTerritories/partners"
import { NotificationParams } from "element-plus"

export const PARTNER_TYPE: Record<string, number> = {
    HOLDING: 0,
    AFFILIATE: 1,
    TERRITORY: 2
}

export const ANCHOR_GENERAL: PartnerAnchor = {
    label: "General information",
    mandatory_fields: ["launch_date"],
    id: "general",
    desc: "Launch date is required to complete the partner settings."
}

export const ANCHOR_LINKING: PartnerAnchor = {
    label: "User linking method",
    mandatory_fields: ["validation_type"],
    id: "linking",
    desc: "User linking method is required to complete the partner settings."
}

export const ANCHOR_VISUAL: PartnerAnchor = {
    label: "Visual identity",
    mandatory_fields: ["logo_url"],
    id: "visual",
    desc: "Logo is required to complete the partner settings."
}

export const ANCHOR_CARPOOL: PartnerAnchor = {
    label: "Carpooling project",
    mandatory_fields: [],
    id: "carpool",
    desc: ""
}

export const HOLDING_MESSAGES: Record<string, NotificationParams> = {
    DELETED: {
        title: "Success",
        message: "Holding Deleted",
        type: "success"
    },
    SAVED: {
        title: "Success",
        message: "Holding Saved",
        type: "success"
    },
    COST_SHARING_ADDED: {
        title: "Success",
        message: "Payment added",
        type: "success"
    }
}

export const AFFILIATE_MESSAGES: Record<string, NotificationParams> = {
    SAVED: {
        title: "Success",
        message: "Affiliate Saved",
        type: "success"
    },
    DELETED: {
        title: "Success",
        message: "Affiliate Deleted",
        type: "success"
    }
}

export const TERRITORY_MESSAGES: Record<string, NotificationParams> = {
    SAVED: {
        title: "Success",
        message: "Territory Saved",
        type: "success"
    },
    DELETED: {
        title: "Success",
        message: "Territory Deleted",
        type: "success"
    }
}

export const ERROR_MESSAGE: NotificationParams = {
    title: "Error",
    message: "An error has occurred. Try again or contact the technical team if it persists.",
    type: "error"
}

export const TOOLTIPS = {
    tags: "Add terms that help users to find holding in the app search bar.<br> Ex: if partner name is International Business Machines, write IBM as key term.<br> If several key terms, write “IBM;Lenovo;Xxxx”. Upper/lower case doesn't matter.<br> <b>Note that all site names will automatically work as key terms for the holding. </b>",
    tags_affiliate:
        "Add terms that help users to find their site in the app search bar. Several terms can be written, separated by ';' Upper/lower case doesn't matter. ",
    employee_count: "Used to calculate account statistics. An approximation is acceptable.",
    siret: "<b>Will be required when you create pricing rules for this partner.</b><br>The SIRET is made up of 14 digits: the 9 digits of the Siren + 5 digits specific to each establishment (NIC).<br>Note: companies have a SIRET per site but we will stock only one SIRET per holding most of cases.<br>By convention, fill in the SIRET of the head office / main site.",
    account_manager:
        "Person in charge of this account (will be the help contact on the SaaS, will receive configuration requests, etc.)",
    user_start_date: "Official launch date for users (first communication campaigns).",
    launch_date:
        "The launch date is indicative. This is not an activation date.<br>Reminder: the partner is visible in the app as soon as it is created",
    contract_start_date:
        "Most of the time, contract start date is the kick-off meeting date (Karos Enterprises).<br/>For Freemium (Karos France programme), write the signing date of the membership form.",
    contract_renewal_date:
        "The renewal date is pre-calculated this way: contract start date + contract duration (in months) - 1 day.<br/>Feel free to change this date.",
    contract_amount: "Fixed contract amount. Exclusion of any added incentive variable.",
    term_of_use:
        "You must write your specific T&Cs on a Word document and send it </br> to the technical team for implementation (3 weeks delay minimum). </br> On the app: the user must accept the T&Cs when linking to the partner (checkbox).",
    help_ressources: "Allows users to get help from their company/school at the partner linking stage. ",
    validation_domains: "Do not write the '@'. Domain name is what comes next.",
    gps: "These GPS coordinates are used to define a site area.<br> Site area is usefull for Geofencing (radius of 500m) or for the application of the company subsidies (radius of 1500m) for exemple.<br>You can modify the latitude and longitude without changing text address.<br> A modification of the text address does not automatically modify the GPS coordinates. You must make this change manually.",
    price_validity:
        "Inform users that the territory's pricing rules will be applied until dd/mm/yyyy. Change this date each time the territory is renewed.</br>Users consult this date in the app > Territory page > Pricing > at the end of the text."
}
