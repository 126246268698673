import { CarpoolApp, CountryConf, CountryConfKey, Teamspace, TeamspaceOption } from "@/models/core"
const COMPANY_COUNTRY_OPTION_FR = [
    {
        value: "fr",
        label: "France"
    },
    {
        value: "be",
        label: "Belgium"
    },
    {
        value: "ch",
        label: "Switzerland"
    },
    {
        value: "lu",
        label: "Luxembourg"
    },
    {
        value: "re",
        label: "Reunion"
    },
    {
        value: "gp",
        label: "Guadeloupe"
    },
    {
        value: "yt",
        label: "Mayotte"
    },
    {
        value: "es",
        label: "Spain"
    }
]

const COMPANY_COUNTRY_OPTION_DK = [
    {
        value: "dk",
        label: "Denmark"
    }
]

const COMPANY_COUNTRY_OPTION_DE = [
    {
        value: "de",
        label: "Germany"
    }
]

const COMPANY_COUNTRY_OPTION_ES = [
    {
        value: "es",
        label: "Spain"
    }
]
const COMPANY_COUNTRY_OPTION_IT = [
    {
        value: "it",
        label: "Italy"
    }
]
const COMPANY_COUNTRY_OPTION_NL = [
    {
        value: "nl",
        label: "Netherlands"
    },
    {
        value: "de",
        label: "Germany"
    }
]
export const ALL_COUNTRY_OPTIONS = [
    ...COMPANY_COUNTRY_OPTION_FR,
    ...COMPANY_COUNTRY_OPTION_DK,
    ...COMPANY_COUNTRY_OPTION_DE,
    ...COMPANY_COUNTRY_OPTION_ES,
    ...COMPANY_COUNTRY_OPTION_IT,
    ...COMPANY_COUNTRY_OPTION_NL
]

const CARPOOL_APP_INDEX_KAROS = 1
const CARPOOL_APP_INDEX_TAMED = 2
const CARPOOL_APP_INDEX_GOFLUX = 3

export const CGU_COUNTRIES_FOR_CARPOOL_APP: Record<CarpoolApp, CountryConfKey[]> = {
    1: ["fr", "es", "ch"],
    2: ["dk"],
    3: ["de"]
}
const DEFAULT_COUNTRY_CONF: Omit<
    CountryConf,
    | "placesApiAuthorizedCountries"
    | "saasEnterpriseUrl"
    | "sassTerritoryUrl"
    | "centerCoords"
    | "countryCenterCoords"
    | "newsFlashCtaLabel"
    | "partnerHelpRessourceContext"
    | "appLanguage"
> = {
    gmapsCountryZoom: 6,
    companyCountryOptions: COMPANY_COUNTRY_OPTION_FR,
    deepLinkPrefix: "karos://",
    moneySign: "€",
    appName: "karos",
    appDisplayName: "Karos",
    primaryColor: "#FF325D",
    gradient: "linear-gradient(90deg, #FF7D63 0%, #FF325D 100%)",
    ticketDefaultMessage: "Hello",
    websiteBridgesUrl: import.meta.env.VITE_WEBSITE_BRIDGES_KAROS,
    displayHomeProductLinks: true,
    carpoolAppIndex: CARPOOL_APP_INDEX_KAROS
}

const COUNTRY_CONFS: Record<CountryConfKey, CountryConf> = {
    fr: {
        ...DEFAULT_COUNTRY_CONF,
        centerCoords: { lat: 48.866667, lng: 2.333333 },
        countryCenterCoords: { lat: 46.227638, lng: 2.213749 },
        placesApiAuthorizedCountries: ["fr", "re", "gp", "yt"],
        newsFlashCtaLabel: "J'ai compris",
        partnerHelpRessourceContext: "Context: “Accéder %context_input%“",
        saasEnterpriseUrl: import.meta.env.VITE_SAAS_ENTERPRISE_KAROS,
        sassTerritoryUrl: import.meta.env.VITE_SAAS_TERRITORY_KAROS,
        appLanguage: "fr"
    },
    dk: {
        centerCoords: { lat: 55.676098, lng: 12.568337 },
        countryCenterCoords: { lat: 56.26392, lng: 9.501785 },
        gmapsCountryZoom: 7,
        placesApiAuthorizedCountries: ["dk"],
        companyCountryOptions: COMPANY_COUNTRY_OPTION_DK,
        deepLinkPrefix: "tamed://",
        moneySign: "kr",
        appName: "tamed",
        appDisplayName: "Ta’Med",
        newsFlashCtaLabel: "Jeg har forstået",
        primaryColor: "#5b8d58",
        gradient: "linear-gradient(90deg, #7BB572 0%, #5B8D58 100%)",
        ticketDefaultMessage: "Hello",
        partnerHelpRessourceContext: "Context: “Acceder %context_input%“",
        saasEnterpriseUrl: import.meta.env.VITE_SAAS_ENTERPRISE_TAMED,
        sassTerritoryUrl: import.meta.env.VITE_SAAS_TERRITORY_TAMED,
        websiteBridgesUrl: import.meta.env.VITE_WEBSITE_BRIDGES_TAMED,
        displayHomeProductLinks: false,
        carpoolAppIndex: CARPOOL_APP_INDEX_TAMED,
        appLanguage: "dk"
    },
    de: {
        centerCoords: { lat: 52.5069704, lng: 13.2846503 },
        countryCenterCoords: { lat: 51.165691, lng: 10.451526 },
        gmapsCountryZoom: 6,
        placesApiAuthorizedCountries: ["de"],
        companyCountryOptions: COMPANY_COUNTRY_OPTION_DE,
        deepLinkPrefix: "goflux://",
        moneySign: "€",
        appName: "goflux",
        appDisplayName: "goFLUX",
        newsFlashCtaLabel: "Verstanden",
        primaryColor: "#1649BA",
        gradient: "linear-gradient(90deg, #7BB572 0%, #5B8D58 100%)",
        ticketDefaultMessage: "Hallo",
        partnerHelpRessourceContext: "Context: “Zugriff %context_input%“",
        saasEnterpriseUrl: import.meta.env.VITE_SAAS_ENTERPRISE_GOFLUX,
        sassTerritoryUrl: import.meta.env.VITE_SAAS_TERRITORY_GOFLUX,
        websiteBridgesUrl: import.meta.env.VITE_WEBSITE_BRIDGES_GOFLUX,
        displayHomeProductLinks: true,
        carpoolAppIndex: CARPOOL_APP_INDEX_GOFLUX,
        appLanguage: "de"
    },
    es: {
        ...DEFAULT_COUNTRY_CONF,
        centerCoords: { lat: 42.854732, lng: -2.675213 },
        countryCenterCoords: { lat: 40.406999, lng: -2.213749 },
        gmapsCountryZoom: 7,
        placesApiAuthorizedCountries: ["es"],
        companyCountryOptions: COMPANY_COUNTRY_OPTION_ES,
        newsFlashCtaLabel: "Entiendo",
        ticketDefaultMessage: "Holà",
        partnerHelpRessourceContext: "Context: “Acceso %context_input%“",
        saasEnterpriseUrl: import.meta.env.VITE_SAAS_ENTERPRISE_KAROS_ES,
        sassTerritoryUrl: import.meta.env.VITE_SAAS_TERRITORY_KAROS_ES,
        appLanguage: "es"
    },
    ch: {
        ...DEFAULT_COUNTRY_CONF,
        centerCoords: { lat: 46.801111, lng: 8.226667 },
        countryCenterCoords: { lat: 46.801111, lng: 8.226667 },
        placesApiAuthorizedCountries: ["fr", "ch"],
        moneySign: "Fr.",
        newsFlashCtaLabel: "Understood",
        partnerHelpRessourceContext: "Context: “Access to %context_input%“",
        saasEnterpriseUrl: import.meta.env.VITE_SAAS_ENTERPRISE_KAROS_CH,
        sassTerritoryUrl: import.meta.env.VITE_SAAS_TERRITORY_KAROS_CH,
        appLanguage: "en"
    },
    it: {
        ...DEFAULT_COUNTRY_CONF,
        centerCoords: { lat: 41.1782835, lng: 7.4301835 },
        countryCenterCoords: { lat: 41.1782835, lng: 7.4301835 },
        placesApiAuthorizedCountries: ["it"],
        newsFlashCtaLabel: "Inteso",
        partnerHelpRessourceContext: "Contesto: “Accesso %context_input%“",
        saasEnterpriseUrl: import.meta.env.VITE_SAAS_ENTERPRISE_KAROS_IT,
        sassTerritoryUrl: import.meta.env.VITE_SAAS_TERRITORY_KAROS_IT,
        appLanguage: "it"
    },
    nl: {
        ...DEFAULT_COUNTRY_CONF,
        centerCoords: { lat: 52.268178, lng: 5.661993 },
        countryCenterCoords: { lat: 52.268178, lng: 5.661993 },
        placesApiAuthorizedCountries: ["nl", "de"],
        newsFlashCtaLabel: "Ik heb het",
        partnerHelpRessourceContext: "Context: “Toegang %context_input%“",
        saasEnterpriseUrl: import.meta.env.VITE_SAAS_ENTERPRISE_KAROS_NL,
        sassTerritoryUrl: import.meta.env.VITE_SAAS_TERRITORY_KAROS_NL,
        appLanguage: "nl"
    }
}

export const TEAMSPACES: Teamspace[] = [
    {
        carpool_app: CARPOOL_APP_INDEX_KAROS,
        country: null
    },
    {
        carpool_app: CARPOOL_APP_INDEX_KAROS,
        country: "es"
    },
    {
        carpool_app: CARPOOL_APP_INDEX_TAMED,
        country: "dk"
    },
    {
        carpool_app: CARPOOL_APP_INDEX_GOFLUX,
        country: "de"
    },
    {
        carpool_app: CARPOOL_APP_INDEX_KAROS,
        country: "ch"
    },
    {
        carpool_app: CARPOOL_APP_INDEX_KAROS,
        country: "it"
    },
    {
        carpool_app: CARPOOL_APP_INDEX_KAROS,
        country: "nl"
    }
]

export const AVAIBLE_TEAMSPACES: TeamspaceOption[] = TEAMSPACES.map(teamspaceItem => {
    const lang = teamspaceItem.country || "fr"
    return {
        label: `${lang.toUpperCase()} ${COUNTRY_CONFS[lang].appDisplayName}`,
        value: teamspaceItem
    } as TeamspaceOption
})

export default COUNTRY_CONFS
