import { createStore } from "vuex"
import List from "@/store/modules/list"
import CallHistory from "@/store/modules/call_history"
import Pois from "@/store/modules/pois"
import Users from "@/store/modules/users"
import remoteConfigs from "@/store/modules/remoteConfigs"

/* Core */
import core from "@/store/core/index"

/* CRM */
import tickets from "@/store/crm/tickets"
import batchMessage from "@/store/crm/batchMessage"
import transitPassValidation from "@/store/crm/transitPass"
import FlashInfos from "@/store/crm/flashInfos"

/* Companies and territories */
import accountManagers from "@/store/companiesAndTerritories/accountManagers"
import mobilityPackage from "@/store/companiesAndTerritories/mobilityPackage"
import mobilityService from "@/store/companiesAndTerritories/mobilityService"
import Partners from "@/store/companiesAndTerritories/partners"
import Holdings from "@/store/companiesAndTerritories/holdings"
import Territories from "@/store/companiesAndTerritories/territories"
import PartnerBenefits from "@/store/companiesAndTerritories/partnerBenefits"
import Affiliates from "@/store/companiesAndTerritories/affiliates"
import pricing from "@/store/companiesAndTerritories/pricing"
import transitPass from "@/store/companiesAndTerritories/transitPass"

/* Other */
import shareConfig from "@/store/other/shareConfig"
import userMap from "@/store/other/userMap"
import karosEvent from "@/store/other/karosEvent"

/* Match */
import match from "@/store/match/match"

/* Various */
import chatroomMessages from "@/store/various/userChatroom"
import userFinder from "@/store/various/userFinder"

export const store = createStore({
    modules: {
        core,
        List,
        CallHistory,
        Pois,
        Users,
        FlashInfos,
        batchMessage,
        transitPass,
        tickets,
        accountManagers,
        mobilityPackage,
        mobilityService,
        Partners,
        Holdings,
        Territories,
        PartnerBenefits,
        Affiliates,
        pricing,
        transitPassValidation,
        shareConfig,
        remoteConfigs,
        userMap,
        karosEvent,
        chatroomMessages,
        userFinder,
        match
    }
})
