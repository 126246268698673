import moment from "@/lib/moment"
import { Macro } from "@/models/crm/ticketing"
import { BaseGeojson, PerimeterGeojson } from "@/models/lib/maps"
import { AxiosResponse } from "axios"
import { Editor } from "tinymce"

export const isEmailValid = (email: any): boolean => {
    let emailRegex =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g
    return email.match(emailRegex) !== null
}

export const downloadFileFromURL = (url: string, fileName: string) => {
    return fetch(url)
        .then(response => response.blob())
        .then(blob => {
            const link = document.createElement("a")
            link.href = URL.createObjectURL(blob)
            link.target = "_blank"
            link.download = fileName
            link.click()
        })
}
export const downloadExcelFile = (response: AxiosResponse): void => {
    const blob = new Blob([response.data], {
        type: (response.headers["content-type"] as string) || undefined
    })
    const link = document.createElement("a")
    link.href = window.URL.createObjectURL(blob)
    link.download = getFileNameFromResponse(response)
    document.body.appendChild(link)
    link.click()
}
const getFileNameFromResponse = (response: AxiosResponse): string => {
    const defaultFileName = "unknown_filename.xlsx"
    const contentDisposition: string | undefined = response.headers["content-disposition"]

    if (!contentDisposition) return defaultFileName

    const regExpFilename = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = regExpFilename.exec(contentDisposition)
    return matches && matches[1] ? matches[1] : defaultFileName
}

export function computeDistance(lat1: any, lon1: any, lat2: any, lon2: any, unit = "K") {
    var radlat1 = (Math.PI * lat1) / 180
    var radlat2 = (Math.PI * lat2) / 180
    var theta = lon1 - lon2
    var radtheta = (Math.PI * theta) / 180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
    if (dist > 1) {
        dist = 1
    }
    dist = Math.acos(dist)
    dist = (dist * 180) / Math.PI
    dist = dist * 60 * 1.1515
    if (unit == "K") {
        dist = dist * 1.609344 * 1000
    }
    if (unit == "N") {
        dist = dist * 0.8684
    }
    return dist / 2
}
export function getLastWeek() {
    let now = new Date()
    return now.setDate(now.getDate() - 7)
}

export function computeSvg() {
    return "M21.8509 3.59605C16.8522 -1.19868 8.74777 -1.19868 3.7491 3.59605C-0.853195 8.01057 -1.26898 15.0351 2.78282 19.9209L12.8 32L22.8172 19.9209C26.869 15.0351 26.4532 8.01058 21.8509 3.59605Z"
}

export const getImageDimensions = (imageBase64: any) => {
    const img = new Image()
    img.src = imageBase64
    return new Promise(resolve => {
        img.onload = (img: any) => {
            resolve({ width: img.target.width, height: img.target.height })
        }
    })
}

export const normalizeString = (str?: string | null): string => {
    if (!str) return ""
    return str
        .toString()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "") // ignore accents
}

export const normalizedFilter = (query: string, array: any[], key: string) => {
    return array.filter(el => {
        return normalizeString(el[key]).includes(normalizeString(query))
    })
}

export const formatDate = (date: any, hours: boolean = false) => {
    if (hours) return moment(date).format("DD-MM-YYYY HH:mm")
    else return moment(date).format("DD-MM-YYYY")
}

export const isNumber = (value: any) => typeof value === "number"

export const copyObjectToReactive = (source: any, vueReactiveTarget: any) => {
    cleanExistingProperties(vueReactiveTarget)
    for (const [key, value] of Object.entries(source)) {
        vueReactiveTarget[key] = value
    }
}
const cleanExistingProperties = (vueReactiveObject: any) => {
    Object.keys(vueReactiveObject).forEach(key => delete vueReactiveObject[key])
}

export const replacePTagsByBr = (htmlString?: string) => {
    if (!htmlString) return ""
    const brString = "<br/>"
    let message = htmlString.replaceAll("<p>", "")
    message = message.replaceAll("</p>", brString)
    return htmlString.includes("<p>") ? message.slice(0, -brString.length) : message
}
export const replacePTagsByLineBreak = (htmlString?: string) => {
    if (!htmlString) return ""
    const lineBreakString = "\n"
    let message = htmlString.replaceAll("<p>", "")
    message = message.replaceAll(lineBreakString, "")
    message = message.replaceAll("</p>", lineBreakString)
    return htmlString.includes("<p>") ? message.slice(0, -lineBreakString.length) : message
}

export const getTextFormattedForTinyMceEditor = (str?: string) => {
    if (!str) return ""
    let messageLines = str.split("\n")
    messageLines = messageLines.map((messageLine: any) => `<p>${messageLine}</p>`)
    return messageLines.join("")
}
export const getTextFormattedForDatabase = (str?: string): string => {
    if (!str) return ""
    else {
        str = replacePTagsByBr(str)
        str = str.replaceAll("\n", "")
        return str
    }
}

export const getPerimeterMapFormattedGeojson = (geojson: BaseGeojson): PerimeterGeojson => {
    return {
        type: "FeatureCollection",
        features: [
            {
                geometry: geojson,
                type: "Feature"
            }
        ]
    }
}

export const getFormattedErrorResponse = (httpResponse: any): any => {
    try {
        if (httpResponse.response.status === 500) return httpResponse
        const response = httpResponse.response.data
        const errorStrings: string[][] = Object.values(response)
        const errorMessage: string = errorStrings.flat().join(". ")
        return errorMessage
    } catch {
        return httpResponse
    }
}
export const numberToStringWithSpaces = (num: number) => {
    let strNum = num.toString()
    let [strInt, strFloats] = strNum.split(".")
    let diplayNumber = strInt.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    if (strFloats) diplayNumber = `${diplayNumber}.${strFloats}`
    return diplayNumber
}
export const convertKmToMeters = (kmValue: number): number => {
    return Math.trunc(kmValue * 1000)
}
export function convertKmToMetersWithDefaultReturn<T>(
    kmValue: number | undefined | null,
    defaultReturnValue: T
): number | T {
    return typeof kmValue === "number" ? convertKmToMeters(kmValue) : defaultReturnValue
}

export const convertMetersToKm = (meterValue: number): number => {
    return meterValue / 1000
}
export function convertMetersToKmWithDefaultReturn<T>(
    meterValue: number | undefined | null,
    defaultReturnValue: T
): number | T {
    return typeof meterValue === "number" ? convertMetersToKm(meterValue) : defaultReturnValue
}

export const convertEurosToCentDisplay = (amount: number): string => {
    return (amount / 100).toFixed(2)
}

export const convertDisplayCurrencyToCents = (euroValue: number): number => {
    return Math.trunc(euroValue * 100)
}
export function convertDisplayCurrencyToCentsWithDefaultReturn<T>(
    value: number | undefined | null,
    defaultReturnValue: T
): number | T {
    return typeof value === "number" ? convertDisplayCurrencyToCents(value) : defaultReturnValue
}

export const convertCentsToDisplayValue = (centValue: number): number => {
    return centValue / 100
}
export function convertCentsToDisplayValueWithDefaultReturn<T>(
    centValue: number | undefined | null,
    defaultReturnValue: T
): number | T {
    return typeof centValue === "number" ? convertCentsToDisplayValue(centValue) : defaultReturnValue
}

export const convertMinutesToSeconds = (timeValue: number): number => {
    return Math.trunc(timeValue * 60)
}

export interface InitializationOptions {
    firstNameToReplace: string | null
    defaultText?: string
}

export const initTinyMceEditorWithMacro = (editor: Editor | null, macro: Macro | undefined): string => {
    let formattedText = ""
    if (macro) {
        formattedText = setEditorContent(editor, macro.message)
    } else {
        if (editor && editor.dom) {
            let paragraphNodes = editor.dom.select("p")
            if (paragraphNodes && paragraphNodes.length > 1) {
                let emptyLineNode = paragraphNodes[1]
                if (emptyLineNode?.firstChild) {
                    editor.selection.setCursorLocation(emptyLineNode.firstChild, 0)
                }
            }
        }
    }
    if (editor && typeof editor.focus === "function") {
        editor.focus()
    }
    return formattedText
}
export const setEditorContent = (editor: Editor | null, text: string): string => {
    let formattedText = getTextFormattedForTinyMceEditor(text)
    editor?.setContent(formattedText)
    return formattedText
}

export const computeExternalLink = (url: string) => {
    return url.includes("http") ? url : `${import.meta.env.VITE_API_BACKEND_URL}${url}`
}
