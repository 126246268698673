import api from "@/lib/Api"

interface Affiliate {
    id: number
    partner_benefit: Array<any>
}

interface State {
    affiliates: Array<Affiliate>
}

const Affiliates = {
    state: {
        affiliates: []
    } as State,
    mutations: {
        syncAffiliates(state: State, affiliates: any) {
            state.affiliates = affiliates
        },
        resetAffiliates(state: State) {
            state.affiliates = []
        },
        createAffiliate(state: State, affiliate: Affiliate) {
            state.affiliates.push(affiliate)
        },
        updateAffiliate(state: State, affiliate: any) {
            let affiliateIndex = state.affiliates.findIndex((a: any) => a.id === affiliate.id)
            if (affiliateIndex < 0) return
            state.affiliates[affiliateIndex] = affiliate
        },
        deleteAffiliate(state: State, affiliateId: number) {
            state.affiliates = state.affiliates.filter((a: any) => a.id !== affiliateId)
        },
        createAffiliateBenefit(state: State, benefit: any) {
            let affiliate: Affiliate | undefined = state.affiliates.find((h: any) => h.id === benefit.company)
            if (!affiliate) return
            affiliate.partner_benefit.push(benefit)
        },
        updateAffiliateBenefit(state: State, benefit: any) {
            let affiliate: Affiliate | undefined = state.affiliates.find((h: any) => h.id === benefit.company)
            if (!affiliate) return
            let partnerBenefit = affiliate.partner_benefit.find((b: any) => b.id === benefit.id)
            partnerBenefit = benefit
        },
        deleteAffiliateBenefit(state: State, benefit: any) {
            let affiliate: Affiliate | undefined = state.affiliates.find((h: any) => h.id === benefit.company)
            if (!affiliate) return
            affiliate.partner_benefit = affiliate.partner_benefit.filter((b: any) => b.id !== benefit.id)
        }
    },
    actions: {
        syncAffiliates({ commit }: { commit: Function }, holdingId: number | string): Promise<any> {
            return new Promise((resolve, reject) => {
                api.get(`/admn/companies/?holding_id=${holdingId}`)
                    .then(affiliates => {
                        commit("syncAffiliates", affiliates)
                        resolve(affiliates)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        createAffiliate({ commit }: { commit: Function }, affiliate: Affiliate): Promise<any> {
            return new Promise((resolve, reject) => {
                api.post("/admn/companies/", affiliate)
                    .then(createdAffiliate => {
                        commit("createAffiliate", createdAffiliate)
                        resolve(createdAffiliate)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        updateAffiliate({ commit }: { commit: Function }, affiliate: Affiliate): Promise<void | any> {
            return new Promise((resolve, reject) => {
                api.patch(`/admn/companies/${affiliate.id}/`, affiliate)
                    .then(updatedAffiliate => {
                        commit("updateAffiliate", updatedAffiliate)
                        resolve(updatedAffiliate)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        deleteAffiliate({ commit }: { commit: Function }, affiliateId: number): Promise<void> {
            return new Promise((resolve, reject) => {
                api.delete(`/admn/companies/${affiliateId}/`)
                    .then(() => {
                        commit("deleteAffiliate", affiliateId)
                        resolve()
                    })
                    .catch((err: any) => {
                        reject(err)
                    })
            })
        }
    },
    getters: {
        getAffiliates: (state: State) => state.affiliates
    }
}
export default Affiliates
