class ListStorage {
    lists_id_raw: any
    lists_id: Array<string | number>
    lists: any
    constructor() {
        this.lists_id_raw = localStorage.getItem("call_list_ids")
        this.lists_id = JSON.parse(this.lists_id_raw) || []
        this.lists = {}
        if (typeof this.lists_id.length === "undefined") this.resetListId()
    }
    getAllList() {
        this.lists = []
        this.lists_id.forEach(id => {
            this.lists.push(this.getList(id))
        })
        return this.lists
    }
    getArchivedList() {
        return this.getAllList().filter((list: any) => !list.archived)
    }
    getList(id: any) {
        var list_raw: any = localStorage.getItem(id)
        return JSON.parse(list_raw) || {}
    }
    updateList(list: any) {
        if (typeof list.id === "undefined") return
        if (this.lists_id.indexOf(list.id) === -1) this.createList(list.id)
        let stringifyList = JSON.stringify(list)
        localStorage.setItem(list.id, stringifyList)
    }
    createList(id: any) {
        this.lists_id.push(id)
        localStorage.setItem("call_list_ids", JSON.stringify(this.lists_id))
    }
    resetListId() {
        console.warn("Warn - Reset list ids")
        localStorage.setItem("call_list_ids", JSON.stringify([]))
    }
    archiveList(list: any) {
        list.archived = true
        this.updateList(list)
    }
    unarchiveList(list: any) {
        list.archived = false
        this.updateList(list)
    }
    deleteList(list: any) {
        var index = this.lists_id.indexOf(list.id)
        if (index > -1) {
            this.lists_id.splice(index, 1)
            localStorage.setItem("call_list_ids", JSON.stringify(this.lists_id))
            localStorage.removeItem(list.id)
        }
    }
    generateId() {
        return "list_id_" + Math.random().toString(36).substr(2, 9)
    }
}
export default new ListStorage()
