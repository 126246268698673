import api from "@/lib/Api"
import { AccountManager } from "@/models/companiesAndTerritories/accountManagers"

interface State {
    accountManagers: AccountManager[]
}

const accountManagers = {
    state: {
        accountManagers: []
    } as State,
    mutations: {
        updateAccountManagers(state: State, accountManagers: any) {
            state.accountManagers = accountManagers
        },
        addAccountManager(state: State, accountManager: any) {
            state.accountManagers.push(accountManager)
        },
        deleteAccountManager(state: State, id: any) {
            state.accountManagers = state.accountManagers.filter(el => el.id !== id)
        }
    },
    actions: {
        syncAccountManagers({ commit }: { commit: Function }) {
            return new Promise((resolve, reject) => {
                api.get("/admn/account_managers/")
                    .then((accountManagers: any) => {
                        accountManagers.forEach((accountManager: any) => {
                            accountManager.full_name = `${accountManager.first_name} ${accountManager.last_name}`
                        })
                        commit("updateAccountManagers", accountManagers)
                        resolve(accountManagers)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        createAccountManager({ commit }: { commit: Function }, accountManagerToCreate: any) {
            return new Promise((resolve, reject) => {
                api.post("/admn/account_managers/", accountManagerToCreate)
                    .then((createdAccountManager: any) => {
                        createdAccountManager.full_name = `${createdAccountManager.first_name} ${createdAccountManager.last_name}`
                        commit("addAccountManager", createdAccountManager)
                        resolve(createdAccountManager)
                    })
                    .catch(err => {
                        let errorText
                        if (err.status === 404) {
                            errorText = "Email doesn't match any admin user. Cannot create account manager profile"
                        } else {
                            errorText = err.response
                        }
                        console.error(err)
                        reject(errorText)
                    })
            })
        },
        updateAccountManager({}, accountManagerToEdit: any) {
            return new Promise((resolve, reject) => {
                api.patch(`/admn/account_managers/${accountManagerToEdit.id}/`, accountManagerToEdit)
                    .then(editedAccountManager => {
                        const accountManager: any = editedAccountManager
                        accountManager.full_name = `${accountManager.first_name} ${accountManager.last_name}`
                        resolve(accountManager)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err.response)
                    })
            })
        },
        deleteAccountManager({ commit }: { commit: Function }, accountManagerToDelete: any): Promise<void> {
            return new Promise((resolve, reject) => {
                api.delete(`/admn/account_managers/${accountManagerToDelete.id}`)
                    .then(() => {
                        commit("deleteAccountManager", accountManagerToDelete.id)
                        resolve()
                    })
                    .catch((err: any) => {
                        console.error(err)
                        reject(err.response)
                    })
            })
        },

        syncAccountManagerPartners({}, accountManagerId: any) {
            return new Promise((resolve, reject) => {
                api.get(`/admn/account_manager_partners/?account_manager_id=${accountManagerId}`)
                    .then(accountManagerPartners => {
                        resolve(accountManagerPartners)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        reassignAccountManagers({}, { accountManagerId, partner_ids }: any): Promise<void> {
            return new Promise((resolve, reject) => {
                api.post(`/admn/account_manager_assignments/?account_manager_id=${accountManagerId}`, { partner_ids })
                    .then(() => {
                        resolve()
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err.response)
                    })
            })
        }
    },
    getters: {
        getAccountManagers: (state: State) => state.accountManagers
    }
}

export default accountManagers
