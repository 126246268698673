import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
type Method = "get" | "post" | "patch" | "put" | "delete"

class Api {
    private _axios: AxiosInstance
    public base: string
    get axios() {
        return this._axios
    }

    constructor() {
        this._axios = axios.create()
        this.updateAdminAuthHeader()
        this.base = `${import.meta.env.VITE_API_BACKEND_URL}/`
    }

    private sendAxiosRequest = (method: Method, url: any, args?: any, config?: AxiosRequestConfig): Promise<any> => {
        const computedUrl = this.getComputedUrl(this.base, url)
        return new Promise((resolve, reject) => {
            this._axios[method](computedUrl, args, config)
                .then(res => {
                    resolve(res.data || res)
                })
                .catch((err: AxiosError) => {
                    import.meta.env.MODE !== "test" && console.log(err)
                    if (err.code == "401") {
                        window.location.href = "/admin/login"
                    }
                    reject(err)
                })
        })
    }
    patch(url: string, args: any): Promise<any> {
        return this.sendAxiosRequest("patch", url, args)
    }
    put(url: string, args: any, config: AxiosRequestConfig) {
        return this.sendAxiosRequest("put", url, args, config)
    }
    post(url: string, args?: any, config?: AxiosRequestConfig): Promise<any> {
        return this.sendAxiosRequest("post", url, args, config)
    }
    get(url: string): Promise<any> {
        return this.sendAxiosRequest("get", url)
    }
    getFile(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        const computedUrl = this.getComputedUrl(this.base, url)
        return new Promise((resolve, reject) => {
            this._axios.get(computedUrl, config).then(
                res => {
                    resolve(res)
                },
                err => {
                    reject(err)
                }
            )
        })
    }
    delete(url: string) {
        return this.sendAxiosRequest("delete", url)
    }
    parseCsv(rawCsv: any, callback: any) {
        var stringCsv = typeof rawCsv !== "string" ? rawCsv.join("\n") : rawCsv
        var loop = 0,
            globalKeys: any = []
        var tmpUser: any = []
        import("papaparse").then(Papa => {
            Papa.parse(stringCsv, {
                worker: true,
                step: (results: any) => {
                    if (loop == 0) globalKeys = results.data
                    else {
                        var obj: any = {}
                        results.data.forEach((val: any, index: number) => {
                            var key = globalKeys[index]
                            obj[key] = val
                        })
                        tmpUser.push(obj)
                    }
                    loop++
                },
                complete: () => {
                    typeof callback === "function" && callback(tmpUser)
                }
            })
        })
    }
    getComputedUrl(baseUrl: string, url: string) {
        if (url.startsWith("http")) return url
        while (url.charAt(0) === "/") {
            url = url.substring(1)
        }
        return `${baseUrl}${url}`
    }
    updateAdminAuthHeader = () => {
        const adminToken = localStorage.getItem(import.meta.env.VITE_AUTH_TOKEN_LABEL)
        if (!adminToken) return
        this._axios.interceptors.request.use(
            config => {
                config.headers["Kadmin-Authorization"] = adminToken
                return config
            },
            error => {
                return Promise.reject(error)
            }
        )
    }
}

export default new Api()
