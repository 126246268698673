import api from "@/lib/Api"
import { BulkPartnerCreationPayload } from "@/models/companiesAndTerritories/partners"

interface State {
    segments: any
}

const partners = {
    state: {
        segments: null
    } as State,
    mutations: {
        updateSegments(state: State, segments: any) {
            state.segments = segments
        }
    },
    actions: {
        syncHoldingsAndTerritories({ dispatch, commit }: { dispatch: Function; commit: Function }, args?: object) {
            return Promise.all([dispatch("syncHoldings", args), dispatch("syncTerritories")])
                .then(([holdings, territories]) => {
                    return { holdings, territories }
                })
                .catch((err: any) => {
                    throw err
                })
        },
        syncSegments({ commit }: { commit: Function }) {
            return new Promise((resolve, reject) => {
                api.get("/segments/")
                    .then(segments => {
                        commit("updateSegments", segments)
                        resolve(segments)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createPartnerFmd({}, fmd: any) {
            return new Promise((resolve, reject) => {
                api.post("/partner_mobility_package/", fmd)
                    .then(createdFmd => {
                        resolve(createdFmd)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        updatePartnerFmd({}, { fmdId, fmd }: any) {
            return new Promise((resolve, reject) => {
                api.patch(`/partner_mobility_package/${fmdId}/`, fmd)
                    .then(updatedFmd => {
                        resolve(updatedFmd)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        updatePartnerAsset({}, { partnerId, assetType, fileName, fileType, data, options }: any): Promise<void> {
            let endpoint = `/partner_asset/?id=${partnerId}&asset_type=${assetType}`
            for (const [key, value] of Object.entries(options)) {
                endpoint = `${endpoint}&${key}=${value}`
            }
            const headers = {
                "Content-Type": fileType,
                "Content-Disposition": `attachment; filename=${fileName}`,
                Accept: "application/json, text/plain, */*"
            }
            return new Promise((resolve, reject) => {
                api.put(endpoint, data, { headers })
                    .then(() => {
                        resolve()
                    })
                    .catch((err: any) => {
                        reject(err)
                    })
            })
        },

        getPartnerSaasAccounts({}, args: any) {
            let endpoint = "/admn/saas_account/?"
            for (const [key, val] of Object.entries(args)) {
                endpoint += `${key}=${val}&`
            }
            return new Promise((resolve, reject) => {
                api.get(endpoint)
                    .then(accounts => {
                        resolve(accounts)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createSaasAccount({}, { account, args }: any) {
            let endpoint = "/admn/saas_account/?"
            for (const [key, val] of Object.entries(args)) {
                endpoint += `${key}=${val}&`
            }
            return new Promise((resolve, reject) => {
                api.post(endpoint, account)
                    .then(createdAccount => {
                        resolve(createdAccount)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        updateSaasAccount({}, { account, params, saasType }: any) {
            return new Promise((resolve, reject) => {
                api.patch(`/admn/saas_account/${account.id}/?saas_type=${saasType}`, params)
                    .then(updatedAccount => {
                        resolve(updatedAccount)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        deleteSaasAccount({}, { account, saasType }: any) {
            return new Promise((resolve, reject) => {
                api.delete(`/admn/saas_account/${account.id}/?saas_type=${saasType}`)
                    .then(() => {
                        resolve({})
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getAccountAuthorizations({}, { accountId, saasType }: any) {
            return new Promise((resolve, reject) => {
                api.get(`/admn/saas_authorization/${accountId}/?saas_type=${saasType}`)
                    .then(authorizations => {
                        resolve(authorizations)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        saveSaasAuthorizations({}, { accountId, saasType, accountAuth }: any) {
            return new Promise((resolve, reject) => {
                api.post(`/admn/saas_authorization/${accountId}/?saas_type=${saasType}`, accountAuth)
                    .then(accountAuth => {
                        resolve(accountAuth)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        verifyBulkPartnersCsv({}, payload: FormData) {
            return new Promise((resolve, reject) => {
                api.post("/admn/add_bulk_partners/", payload, {})
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createBulkPartners({}, payload: BulkPartnerCreationPayload): Promise<void> {
            return new Promise((resolve, reject) => {
                api.post("/admn/add_bulk_partners_create/", payload)
                    .then(() => {
                        resolve()
                    })
                    .catch(() => {
                        reject()
                    })
            })
        }
    },
    getters: {
        getSegments: (state: State) => state.segments
    }
}

export default partners
