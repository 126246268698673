import Api from "@/lib/Api"
import { Territory } from "@/models/companiesAndTerritories/partners"

import { convertMinutesToSeconds, convertDisplayCurrencyToCents } from "@/lib/utils"
interface State {
    territories: Territory[]
    territoryHoldingId: number
}

const convertTerritoryUnitsForFrontend = (el: Territory) => {
    if (el.transit_concurrency_max_walking_duration)
        el.transit_concurrency_max_walking_duration = el.transit_concurrency_max_walking_duration / 60
    if (el.monthly_funding_limit) el.monthly_funding_limit = el.monthly_funding_limit / 100
    return el
}

const convertTerritoryUnitsForBackend = (el: Territory) => {
    if (el.transit_concurrency_max_walking_duration)
        el.transit_concurrency_max_walking_duration = convertMinutesToSeconds(
            el.transit_concurrency_max_walking_duration
        )
    if (el.monthly_funding_limit) el.monthly_funding_limit = convertDisplayCurrencyToCents(el.monthly_funding_limit)
    return el
}

export default {
    state: {
        territories: [],
        territoryHoldingId: 0
    } as State,
    mutations: {
        updateTerritories(state: State, territories: Territory[]) {
            state.territories = territories
        },
        deleteTerritory(state: State, territoryId: number) {
            state.territories = state.territories.filter((t: Territory) => t.id !== territoryId)
        },
        createTerritory(state: State, territory: Territory) {
            state.territories.push(territory)
        },
        updateTerritoryHoldingId(state: State, id: number) {
            state.territoryHoldingId = id
        },
        updateTerritory(state: State, territory: Territory) {
            let territoryToEditIndex = state.territories.findIndex((t: Territory) => t.id === territory.id)
            if (territoryToEditIndex < 0) return
            state.territories[territoryToEditIndex] = territory
        },
        createTerritoryBenefit(state: State, benefit: any) {
            let territory = state.territories.find(t => t.id === benefit.company)
            if (!territory) return
            territory.partner_benefit.push(benefit)
        },
        updateTerritoryBenefit(state: State, benefit: any) {
            let territory = state.territories.find((t: Territory) => t.id === benefit.company)
            if (!territory) return
            let partnerBenefit = territory.partner_benefit.find((b: any) => b.id === benefit.id)
            partnerBenefit = benefit
        },
        deleteTerritoryBenefit(state: State, benefit: any) {
            let territory = state.territories.find((t: any) => t.id === benefit.company)
            if (!territory) return
            territory.partner_benefit = territory.partner_benefit.filter((b: any) => b.id !== benefit.id)
        },
        updateTerritoryLinkScriptStatus(state: State, { id, status }: { id: number; status: any }) {
            let territory = state.territories.find(t => t.id === id)
            if (!territory) return
            territory.last_add_users_to_partner = status.last_add_users_to_partner
        }
    },
    actions: {
        syncTerritories({ commit }: { commit: Function }) {
            return new Promise((resolve, reject) => {
                Api.get(`/admn/territories/`)
                    .then((territories: Territory[]) => {
                        territories.forEach(element => convertTerritoryUnitsForFrontend(element))
                        commit("updateTerritories", territories)
                        resolve(territories)
                    })
                    .catch(err => {
                        console.error(err)
                        reject(err)
                    })
            })
        },
        createTerritory({ commit, state }: { commit: Function; state: State }, territory: Territory) {
            return new Promise((resolve, reject) => {
                territory.holding = state.territoryHoldingId
                Api.post(`/admn/companies/`, territory)
                    .then((createdTerritory: Territory) => {
                        commit("createTerritory", convertTerritoryUnitsForFrontend(createdTerritory))
                        resolve(createdTerritory)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        updateTerritory({ commit }: { commit: Function }, territory: Territory) {
            return new Promise((resolve, reject) => {
                Api.patch(`/admn/companies/${territory.id}/`, convertTerritoryUnitsForBackend(territory))
                    .then((updatedTerritory: Territory) => {
                        commit("updateTerritory", convertTerritoryUnitsForFrontend(updatedTerritory))
                        resolve(updatedTerritory)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        deleteTerritory({ commit }: { commit: Function }, territoryId: number): Promise<void> {
            return new Promise((resolve, reject) => {
                Api.delete(`/admn/companies/${territoryId}/`)
                    .then(() => {
                        commit("deleteTerritory", territoryId)
                        resolve()
                    })
                    .catch((err: any) => {
                        reject(err)
                    })
            })
        },
        runLinkScript({ commit }: { commit: Function }, territoryId: number): Promise<void> {
            return new Promise((resolve, reject) => {
                Api.post(`/admn/add_existing_users_to_partner/${territoryId}/`)
                    .then((response: any) => {
                        commit("updateTerritoryLinkScriptStatus", { id: territoryId, status: response })
                        resolve(response)
                    })
                    .catch(e => {
                        reject(e)
                    })
            })
        }
    },
    getters: {
        getTerritories: (state: State) => state.territories
    }
}
