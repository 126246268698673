import api from "@/lib/Api"
import { PARTNER_TYPE } from "@/constants/companiesAndTerritories/partners"

const benefits = {
    actions: {
        getBenefitDefaultDescriptions() {
            return new Promise((resolve, reject) => {
                api.get("/admn/partner-benefit-kinds/")
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        createBenefit({ commit }: { commit: Function }, { benefit, partnerType }: any) {
            return new Promise((resolve, reject) => {
                api.post("/admn/partner_benefits/", benefit)
                    .then(createdBenefit => {
                        switch (partnerType) {
                            case PARTNER_TYPE.HOLDING:
                                commit("createHoldingBenefit", createdBenefit)
                                break
                            case PARTNER_TYPE.AFFILIATE:
                                commit("createAffiliateBenefit", createdBenefit)
                                break
                            case PARTNER_TYPE.TERRITORY:
                                commit("createTerritoryBenefit", createdBenefit)
                                break
                        }
                        resolve(createdBenefit)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        updateBenefit({ commit }: { commit: Function }, { benefit, partnerType }: any) {
            return new Promise((resolve, reject) => {
                api.patch(`/admn/partner_benefits/${benefit.id}/`, benefit)
                    .then(updatedBenefit => {
                        switch (partnerType) {
                            case PARTNER_TYPE.HOLDING:
                                commit("updateHoldingBenefit", updatedBenefit)
                                break
                            case PARTNER_TYPE.AFFILIATE:
                                commit("updateAffiliateBenefit", updatedBenefit)
                                break
                            case PARTNER_TYPE.TERRITORY:
                                commit("updateTerritoryBenefit", updatedBenefit)
                                break
                        }
                        resolve(updatedBenefit)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        deleteBenefit({ commit }: { commit: Function }, { benefit, partnerType }: any): Promise<void> {
            const id = Number.isInteger(benefit.id) ? benefit.id : benefit
            return new Promise((resolve, reject) => {
                api.delete(`/admn/partner_benefits/${id}/`)
                    .then(() => {
                        switch (partnerType) {
                            case PARTNER_TYPE.HOLDING:
                                commit("deleteHoldingBenefit", benefit)
                                break
                            case PARTNER_TYPE.AFFILIATE:
                                commit("deleteAffiliateBenefit", benefit)
                                break
                            case PARTNER_TYPE.TERRITORY:
                                commit("deleteTerritoryBenefit", benefit)
                                break
                        }
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    }
}

export default benefits
