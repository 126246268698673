interface State {
    pageName: any
    pageDescription?: string | null
}

export default {
    state: {
        adminUser: null,
        pageName: "",
        pageDescription: ""
    } as State,
    mutations: {
        updatePageDescription(state: State, description: string | null | undefined) {
            state.pageDescription = description
        }
    },
    getters: {
        getPageDescription: (state: State) => state.pageDescription
    }
}
